<template>
  <div class="DashBoard">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo titulo="Termos" @OpenMenuLateral="menuOpen = true"></Topo>
      <div class="container mt-3 p-3 mb-5">
        <div v-if="!itemToSign">
          <b-list-group v-if="termos.length > 0">
            <b-list-group-item
              v-for="item in termos"
              :key="item.id"
              :variant="buscaClasse(item.assinatura)"
            >
              {{ item.tipoContrato }} - {{ formataData(item.dataHora) }}
              <b-badge :variant="buscaClasse(item.assinatura)" class="mr-3">{{
                item.placa
              }}</b-badge>
              <b-badge :variant="buscaClasse(item.assinatura)">{{
                item.assinatura == null
                  ? 'não assinado'
                  : 'assinado em ' + formataData(item.dataAssinatura)
              }}</b-badge>
              <div v-if="item.assinatura == null" class="mt-2">
                <button
                  @click="assinar(item)"
                  class="btn btn-success btn-block"
                  type="button"
                  id="button-addon2"
                >
                  Assinar
                </button>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div v-else class="alert alert-danger">Nenhum Termo Encontrado</div>
        </div>
        <div v-else>
          <h4>{{ itemToSign.tipoContrato }}</h4>
          <hr />
          <div v-html="itemToSign.modeloContrato"></div>
          <div class="mb-2" style="border-bottom: 1px solid black">
            <b-button class="botaoLimpar" @click="limparAssinatura()">
              <i class="fa fa-fw fa-eraser"></i> Limpar
            </b-button>
            <VueSignaturePad
              width="100%"
              :style="heightAssinatura"
              ref="signaturePad"
            />
          </div>
          <div>{{ itemToSign.associado }}</div>
          <small>assine no espaço em branco acima</small>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="button"
            @click="enviarAssinatura()"
            variant="info"
            class="btn btn-block my-3 mb-5"
            style="margin-bottom: 100px"
            >ASSINAR</b-button
          >
        </div>
      </div>
      <div class="m-5">&nbsp;</div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './TopoInterno';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import pdf from 'vue-pdf';
import moment from 'moment';
import canvasToBlob from 'blueimp-canvas-to-blob';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      itemToSign: null,
      termos: [],
      files: new FormData(),
      carregando: false,
      regulamento: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      heightAssinatura: { height: '215px' },
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { Topo, ConteudoDash, MenuRodape, MenuLateral, pdf },
  methods: {
    enviarAssinatura() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty == true) {
        this.$bvToast.toast('É necessário fazer sua assinatura', {
          title: 'Atenção!',
          solid: true,
          variant: 'danger',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 3500,
        });
        return;
      } else {
        var blob = canvasToBlob(data);
        this.uploadSignature(blob);
      }
    },
    uploadSignature(blob) {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        this.files.set('file', blob, 'assinatura.png');

        service
          .upload('Upload', 'Termos/' + this.itemToSign.id_origem, this.files)
          .then((response) => {
            console.log(response);
            this.carregando = false;
            this.itemToSign.assinatura = response;
            resolve(response);
            this.salvaTermo();
          })
          .catch(function (error) {
            this.carregando = false;
            reject();
          });
      });
    },
    salvaTermo() {
      this.carregando = true;
      var form = {};
      form.modeloContrato = this.itemToSign.modeloContrato;
      form.id_origem = this.itemToSign.id_origem;
      form.origem = this.itemToSign.origem;
      form.id_assinatura = this.itemToSign.id_assinatura;
      form.assinatura = this.itemToSign.assinatura;
      console.log(this.itemToSign);
      service
        .post('Termos', 'salvaTermo', form)
        .then((res) => {
          this.$bvToast.toast('Termo Assinado com Sucesso', {
            title: 'Atenção!',
            solid: true,
            variant: 'success',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 3500,
          });

          this.carregando = false;
          this.itemToSign = null;
          this.buscaDados();
        })
        .catch((e) => {
          this.$bvToast.toast('Não foi possível assinar o termo', {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 3500,
          });
          this.carregando = false;
        });
    },
    limparAssinatura() {
      this.$refs.signaturePad.clearSignature();
    },
    verificaAssinatura() {
      var retorno = false;
      if (this.$refs.signaturePad) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        retorno = !isEmpty;
      }

      return retorno;
    },
    assinar(item) {
      this.itemToSign = item;
      console.log(this.itemToSign);
      this.itemToSign.dadosJson = JSON.parse(this.itemToSign.dadosJson);
      this.replaceDadosTermo();
    },
    replaceDadosTermo() {
      if (this.itemToSign.origem == 'Cancelamento') {
        this.itemToSign.modeloContrato = this.itemToSign.modeloContrato
          .replace('#NOME#', this.itemToSign.dadosJson.associado.nome)
          .replace('#CPF#', this.itemToSign.dadosJson.associado.cpfcnpj)
          .replace('#TELEFONE#', this.itemToSign.dadosJson.associado.telefone)
          .replace('#CELULAR#', this.itemToSign.dadosJson.associado.celular)
          .replace('#EMAIL#', this.itemToSign.dadosJson.associado.email)
          .replace('#ENDERECO#', this.itemToSign.dadosJson.associado.endereco)
          .replace('#NUMERO#', this.itemToSign.dadosJson.associado.numero)
          .replace(
            '#COMPLEMENTO#',
            this.itemToSign.dadosJson.associado.complemento,
          )
          .replace('#BAIRRO#', this.itemToSign.dadosJson.associado.bairro)
          .replace('#CIDADE#', this.itemToSign.dadosJson.associado.cidade)
          .replace('#ESTADO#', this.itemToSign.dadosJson.associado.estado)
          .replace('#MARCA#', this.itemToSign.dadosJson.veiculo.marca)
          .replace('#MODELO#', this.itemToSign.dadosJson.veiculo.modelo)
          .replace(
            '#ANOFABRICACAO#',
            this.itemToSign.dadosJson.veiculo.ano_fabricacao,
          )
          .replace('#ANOMODELO#', this.itemToSign.dadosJson.veiculo.ano_modelo)
          .replace('#GRUPO#', this.itemToSign.dadosJson.veiculo.categoria)
          .replace('#PLACA#', this.itemToSign.dadosJson.veiculo.placa)
          .replace('#RENAVAM#', this.itemToSign.dadosJson.veiculo.renavam)
          .replace('#CHASSI#', this.itemToSign.dadosJson.veiculo.chassi)
          .replace('#NOMEASSOCIACAO#', this.itemToSign.empresa)
          .replace('#EMAILASSOCIACAO#', this.itemToSign.emailEmpresa)
          .replace('#MOTIVO#', this.itemToSign.nomeMotivo)
          .replace('#CIDADE#', this.itemToSign.dadosJson.associado.cidade)
          .replace('#DATAHORA#', this.formataData(this.itemToSign.dataAtual))
          .replace('#DATAHORA#', this.formataData(this.itemToSign.dataAtual));
      } else {
        this.itemToSign.modeloContrato = this.itemToSign.termoPreenchido
          .replace('#DATAHORA#', this.formataData(this.itemToSign.dataAtual))
          .replace('#DATAHORA#', this.formataData(this.itemToSign.dataAtual));
      }
    },
    buscaClasse(assinatura) {
      if (assinatura != null) return 'success';
      else return 'warning';
    },
    formataRegional(id) {
      return id.toString().padStart(4, '0');
    },
    formataId(id) {
      return id.toString().padStart(6, '0');
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formataAno(data) {
      return moment(data).format('YYYY');
    },
    buscaDados() {
      this.carregando = true;

      service
        .getAll('Termos', {}, 'list')
        .then((res) => {
          console.log(res);
          this.termos = res.data;
          this.termos.sort(function (a, b) {
            return a.dataHora < b.dataHora
              ? -1
              : a.dataHora > b.dataHora
              ? 1
              : 0;
          });

          this.carregando = false;
        })
        .catch((e) => {
          this.carregando = false;
          this.erroConexao =
            'Seu aplicativo parece estar offline, verifique sua conexao';
        });
    },
  },
  mounted() {
    this.buscaDados();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}

.iconeFechar {
  font-size: 28px;
}
.bm-menu {
  background: #373a47;
}
.bm-menu {
  background-color: #3f3f41;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.2s;
  width: 0;
  z-index: 9999;
  color: #fff;
}
.bm-item-list > * {
  display: flex;
  padding: 0.7em;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.bm-item-list > * > i {
  color: #666;
  font-weight: 700;
  margin-left: 10px;
}
.logoBottom {
  margin-top: 100px;
  text-align: center;
  opacity: 0.5;
}

.cartao {
  font-family: 'Play', sans-serif;
  position: relative;
  margin-bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url('https://bboneapp.s3.amazonaws.com/mobile/bg-cartao.png');
}

.cartao .inside {
  padding: 5%;
}

.cartao .inside .assistencia,
.cartao .inside .central {
  font-size: 10px;
}

.cartao .inside .telefone-assistencia,
.cartao .inside .telefone-central {
  font-size: 12px;
}

.cartao .inside .telefone-assistencia a,
.cartao .inside .telefone-central a {
  color: #373a47;
}

.numero-cartao {
  margin-top: 10px;
  text-align: center;
}

.nome-small,
.desde-small {
  font-size: 10px;
}

.nome,
.desde {
  font-size: 13px;
}

@media only screen and (orientation: landscape) {
  .cartao .inside h5 {
    font-size: 30px;
  }

  .cartao .inside .assistencia,
  .cartao .inside .central {
    font-size: 14px;
    margin-top: 10px;
  }

  .cartao .inside .telefone-assistencia,
  .cartao .inside .telefone-central {
    font-size: 18px;
  }

  .numero-cartao {
    margin-top: 20px;
    text-align: center;
    font-size: 38px;
  }

  .nome-small,
  .desde-small {
    font-size: 14px;
    margin-top: 20px;
  }

  .nome,
  .desde {
    font-size: 18px;
  }
}
</style>
