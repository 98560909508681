<template>
  <div>
    <b-modal
      id="modalAssinaturaTermos"
      centered
      hide-footer
      hide-header
      @hidden="modalInfo = preventCloseModal()"
      v-model="modalInfo"
    >
      <h3>Assine o termo digital</h3>
      <h5>Aceite o termo para continuar utilizando o app.</h5>
      <b-button
        :disabled="carregando"
        @click="buscaDadosContrato()"
        class="botaoTermo mt-4"
        variant="light"
        >VERIFICAR</b-button
      >
    </b-modal>

    <b-modal hide-header hide-footer id="modalContrato" v-model="exibeContrato">
      <div class="bg-gray container">
        <div
          class="mt-3"
          v-html="termosPendentes.modeloContrato"
          style="text-align: justify"
        ></div>
        <b-form-checkbox
          id="checkbox-1"
          v-model="form.termo"
          name="Termo de Adesão"
          :value="termosPendentes"
          :unchecked-value="null"
          v-validate="{ required: true }"
          :state="validateState('Termo de Adesão')"
          >Li e Aceito os Termos</b-form-checkbox
        >
        <div class="mt-3 text-right">
          <b-button @click="aceiteContrato" variant="primary">OK</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from '../../services';
import jwt from 'jsonwebtoken';

export default {
  name: 'modalAssinaturaTermo',
  props: ['assinaturaTermo'],
  watch: {
    assinaturaTermo() {
      this.acao = this.assinaturaTermo.action;
      this.modalInfo = this.assinaturaTermo.flag;
      if (this.assinaturaTermo.idsTermosPendentes)
        this.idsTermosPendentes = this.assinaturaTermo.idsTermosPendentes;
      if (this.assinaturaTermo.exibeContrato)
        this.exibeContrato = this.assinaturaTermo.exibeContrato;
      if (this.assinaturaTermo.resposta == 'verificarNovamente')
        this.buscaDadosContrato();
    },
  },
  data() {
    return {
      modalInfo: false,
      termosPendentes: '',
      exibeContrato: false,
      idsTermosPendentes: null,
      carregando: null,
      acao: null,
      idEmpresa: null,
      form: {
        termo: null,
      },
    };
  },
  components: {},
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    buscaDadosContrato() {
      this.carregando = true;

      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        this.idEmpresa = this.decoded.id_empresa;
      } else {
        this.idEmpresa = this.$route.params.idEmpresa;
      }

      console.log('idEmpresa', this.idEmpresa);

      service
        .post('termos-pendentes', 'BuscaContrato', {
          idEmpresa: this.idEmpresa,
        })
        .then((res) => {
          if (this.acao == 'termoHibrido') {
            this.termosPendentes = res.data.find(
              (x) => x.tipoContrato == 'Proposta de Filiação',
            );
          }
          if (this.acao == 'termosPendentes') {
            if (this.idsTermosPendentes.length > 0)
              this.termosPendentes = res.data.find(
                (x) => x.idModelo == this.idsTermosPendentes[0],
              );
          }
          this.exibeContrato = true;
          this.carregando = false;
        });
    },
    aceiteContrato() {
      this.$validator.validate('Termo de Adesão').then((result) => {
        if (result) {
          this.exibeContrato = false;
          if (this.$route.params.idAssociado) {
            this.termosPendentes.idAssociado = this.$route.params.idAssociado;
          }
          service
            .post(
              'insert-termos',
              'salvarTermoVerificado',
              this.termosPendentes,
            )
            .then(() => {
              if (
                this.idsTermosPendentes &&
                this.idsTermosPendentes.length > 0
              ) {
                this.carregando = true;
                this.$emit('resposta', 'verificarNovamente');
              } else {
                this.carregando = false;
                if (this.acao == 'termoHibrido')
                  this.$emit('resposta', 'aceiteContrato');
              }
            });
        }
      });
    },
    preventCloseModal() {
      if (this.acao) {
        return true;
      } else {
        this.carregando = false;
        return false;
      }
    },
  },
};
</script>

<style>
#modalAssinaturaTermos .modal-content {
  background: #078fcf;
  border-radius: 7px;
  padding: 10px;
  color: white;
}

#modalAssinaturaTermos h3 {
  font-weight: bold;
}
.botaoTermo {
  border-radius: 5px !important;
  font-size: 14px;
  padding: 5px 20px;
}
#modalContrato .modal-content {
  margin-bottom: 90px;
}
</style>
