<template>
  <div class="metas p-2 mt-1 mb-3 text-white">
    <b-row>
      <b-col>
        <h3 class="m-0 p-0 text-right">Objetivos {{ $route.params.idCotacao }}</h3>
        <h5 class="text-right my-1">2020</h5>
      </b-col>
      <b-col cols="8" class="text-right">
        <b-progress :value="value" :max="max" variant="success" animated></b-progress>
        <h5 class="text-right mt-2 p-0 mb-0">200 | 500</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "DashBoard",
  props: {
    msg: String
  },
  data() {
    return {
      value: 80,
      max: 100
    };
  },
  components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.metas {
  font-size: 11px;
  background-color: black;
  border-radius: 10px;
}
.iconeChart {
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  background-color: slategrey;
}
.avatar {
  width: 80px;
  height: 80px;
  /*position: relative;*/
}
.nota {
  margin-top: -10px;
  /* clear: both;
  position: relative;*/
}
.metas h3 {
  font-size: 15px;
  font-weight: bold;
}

.metas h5 {
  font-size: 11px;
}
</style>
