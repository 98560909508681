var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"listaCotacoes"},[(_vm.cotacoes.length == 0)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(" Nenhuma Cotação Encontrada ")]):_c('swipe-list',{ref:"list",staticClass:"card",attrs:{"disabled":!_vm.enabled,"items":_vm.preCotacoes,"item-key":"id","revealed":_vm.revealed,"item-disabled":function (ref) {
            var disabled = ref.disabled;

            return disabled;
}},on:{"update:revealed":function($event){_vm.revealed=$event},"closed":function($event){return _vm.setLastEvent('closed', $event)},"leftRevealed":function($event){return _vm.setLastEvent('leftRevealed', $event)},"rightRevealed":function($event){return _vm.setLastEvent('rightRevealed', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{ref:"content",staticClass:"card-content",on:{"click":function($event){return _vm.itemClick(item)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"1"}},[_c('span',{class:("badge badge-" + (_vm.getClasStatus(item.status)))},[_c('font-awesome-icon',{staticClass:"font14",attrs:{"icon":['fa', ("" + (_vm.getIconeTipo(item)))]}})],1)]),_c('b-col',[_vm._v(" "+_vm._s(_vm.formataNome(item.nome))+" "),_c('div',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s((item.jsonRetorno.dadosPlaca && item.jsonRetorno.dadosPlaca.placa) || '')+" ")])]),_c('b-col',{staticClass:"text-center padding-0",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.nomeStatus)+" "),_c('div',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.exibeDias(item)))])]),(
              _vm.decoded.tipo != 'Associado' || item.nomeStatus == 'Finalizada'
            )?_c('b-col',{attrs:{"cols":"1"}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left"}})],1):_vm._e()],1),(item.exibeDetalhe == true)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"1"}}),_c('b-col',{staticClass:"pb-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("id:")])]),_c('b-col',[_vm._v(_vm._s(item.idPreCotacao))])],1),(item.dataConversao)?_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Data da conversão:")])]),_c('b-col',[_vm._v(_vm._s(item.dataConversao.slice(0, 10).split('-').reverse().join('/')))])],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Telefone:")])]),_c('b-col',[_vm._v(_vm._s(item.celular))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Email:")])]),_c('b-col',[_vm._v(_vm._s(item.email))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Marca/Modelo:")])]),_c('b-col',[_vm._v(_vm._s(item.jsonRetorno.dadosPlaca && item.jsonRetorno.dadosPlaca.marcaModelo))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Grupo:")])]),_c('b-col',[_vm._v(_vm._s(item.jsonRetorno.grupoSeleciondo.label))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Estado:")])]),_c('b-col',[_vm._v(_vm._s(item.estado))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Cidade:")])]),_c('b-col',[_vm._v(_vm._s(item.cidade))])],1)],1)],1):_vm._e()],1)]}},{key:"right",fn:function(ref){
            var item = ref.item;
return [(item.idPropostaConvertida === null)?_c('div',{staticClass:"swipeout-action purple",on:{"click":function($event){return _vm.converter(item.idPreCotacao)}}},[_c('i',{staticClass:"fa fa-copy"})]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }