<template>
  <div>
    <div v-if="carregando == true" class="carregando">
      <!--img width="100" src="https://bboneapp.s3.amazonaws.com/evo-white.png" /-->
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div v-if="itemDetalheAux" class="detalhe ml-2 mr-2 p-3">
      <b-row>
        <b-col class="text-right">
          <font-awesome-icon icon="times" @click="returnLista" />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="1">
          <span
            :class="`badge badge-${getClasStatus(itemDetalheAux.nomeStatus)}`"
          >
            <!--i :class="`${getIconeTipo(itemDetalheAux.tipo)} `"></i-->
            <font-awesome-icon
              :icon="['fa', `${getIconeTipo(itemDetalheAux.nomeTipo)}`]"
              class="font14"
            />
          </span>
        </b-col>
        <b-col>
          {{ formataNome(itemDetalheAux.dadosCliente.associado) }}
          <div class="text-secondary">
            {{ itemDetalheAux.dadosVeiculo.placa }}
          </div>
        </b-col>
        <b-col cols="3" class="text-center padding-0">
          {{ somaValorTotal(itemDetalheAux.produtos) | currency }}
          <div class="text-secondary">
            {{ somaValorTotalMes(itemDetalheAux.produtos) | currency }} / mês
          </div>
        </b-col>
        <b-col cols="3" class="text-center padding-0">
          {{ itemDetalheAux.nomeStatus }}
          <div class="text-secondary">{{ exibeDias(itemDetalheAux) }}</div>
        </b-col>
        <!--b-col cols="1">
              <font-awesome-icon icon="bars" />
        </b-col-->
      </b-row>
      <b-row class="mt-3">
        <b-col cols="4">
          <b>Protocolo:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.idProposta }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Email:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.dadosCliente.email }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Telefone:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.dadosCliente.telefone }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Celular:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.dadosCliente.celular }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Cidade:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.dadosCliente.cidade }}</b-col>
      </b-row>
      <hr />
      <b-row class="mt-3">
        <b-col cols="4">
          <b>Tipo Veiculo:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.nomeGrupo }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Código Fipe:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.dadosVeiculo.codigoFipe }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Valor Fipe:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.dadosVeiculo.valorFipe | currency }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Valor Coberto:</b>
        </b-col>
        <b-col>{{
          itemDetalheAux.dadosVeiculo.valorCobertura | currency
        }}</b-col>
      </b-row>
      <b-row v-if="itemDetalheAux.implemento">
        <b-col cols="4">
          <b>Vlr. conjunto:</b>
        </b-col>
        <b-col>{{ itemDetalhe.valorTotalConjunto | currency }}</b-col>
      </b-row>
      <b-row v-if="itemDetalheAux.isZero">
        <b-col cols="4">
          <b>Zero KM:</b>
        </b-col>
        <b-col>
          NF: {{ itemDetalheAux.nf }}
          <Br />
          Data:{{ itemDetalheAux.dataNf }}
          <br />
          valor: {{ itemDetalheAux.valorNf | currency }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Cota:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.cotaTabela }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Participação:</b>
        </b-col>
        <b-col>
          <span
            v-if="
              itemDetalheAux.valorComplementoParticipacao &&
              itemDetalheAux.valorComplementoParticipacao > 0
            "
            >{{
              itemDetalheAux.valorComplementoParticipacao | currency
            }}
            +</span
          >
          {{ itemDetalheAux.valorCotaParticipacao }}% da FIPE, mínimo de
          {{ itemDetalheAux.valorParticipacaoMinimo | currency }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Mensalidade:</b>
        </b-col>
        <b-col>
          {{ somaValorTotalMes(itemDetalheAux.produtos) | currency }}
          <span v-if="itemDetalheAux.diaVencimentoMensalidade"
            >todo dia {{ itemDetalheAux.diaVencimentoMensalidade }}</span
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b>Adesão:</b>
        </b-col>
        <b-col>{{ somaValorTotal(itemDetalheAux.produtos) | currency }}</b-col>
      </b-row>
      <hr />
      <b-row class="mt-3">
        <b-col cols="3">
          <b>Produtos:</b>
        </b-col>
        <b-col>
          <ul class>
            <li
              v-for="itemDetalheAux in itemDetalheAux.produtos"
              :key="itemDetalheAux.id"
            >
              {{ itemDetalheAux.nomeProduto }}
            </li>
          </ul>
        </b-col>
      </b-row>
      <hr v-if="itemDetalheAux.restricoes.length > 0" />
      <b-row class="mt-3" v-if="itemDetalheAux.restricoes.length > 0">
        <b-col cols="3">
          <b>Restrições:</b>
        </b-col>
        <b-col>
          <ul class>
            <li
              v-for="itemDetalheAux in itemDetalheAux.restricoes"
              :key="itemDetalheAux.id"
            >
              {{ itemDetalheAux.descricao }}
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row v-if="itemDetalheAux.dataAdesao">
        <b-col cols="4">
          <hr />
          <b>Data Adesão:</b>
        </b-col>
        <b-col>{{ itemDetalheAux.dataAdesao }}</b-col>
      </b-row>
      <b-row v-if="itemDetalheAux.dataVistoria">
        <b-col cols="4">
          <b>Data Vistoria:</b>
        </b-col>
        <b-col>{{ ajustaDataHoraUtc(itemDetalheAux.dataVistoria) }}</b-col>
      </b-row>
      <hr
        v-if="this.itemDetalheAux.agregado || this.itemDetalheAux.implemento"
      />

      <div
        v-if="
          itemDetalheAux.implemento && itemDetalheAux.implementos.length > 0
        "
      >
        <div
          v-for="(implemento, index) in itemDetalheAux.implementos"
          :key="index"
        >
          <b-row class="mt-3">
            <b-col cols="1">
              <span
                :class="`badge badge-${getClasStatus(
                  itemDetalheAux.nomeStatus,
                )}`"
              >
                <font-awesome-icon
                  :icon="['fa', `${getIconeTipo(implemento.tipo)}`]"
                  class="font14"
                />
              </span>
            </b-col>
            <b-col>
              {{ formataNome(implemento.descricao) }}
              <div class="text-secondary">
                {{ implemento.placa }}
              </div>
            </b-col>
            <b-col cols="3" class="text-center padding-0">
              {{ itemDetalheAux.nomeStatus }}
              <div class="text-secondary">
                {{ exibeDias(itemDetalheAux) }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="4">
              <b>Tipo Veiculo:</b>
            </b-col>
            <b-col>{{ implemento.tipo }}</b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <b>Descrição:</b>
            </b-col>
            <b-col>{{ implemento.tipo }}</b-col>
          </b-row>
          <b-row v-if="implemento.placa" class="mt-1">
            <b-col cols="4">
              <b>Placa:</b>
            </b-col>
            <b-col>{{ implemento.placa }}</b-col>
          </b-row>
          <b-row v-if="implemento.chassi" class="mt-1">
            <b-col cols="4">
              <b>Chassi:</b>
            </b-col>
            <b-col>{{ implemento.chassi }}</b-col>
          </b-row>
          <b-row v-if="implemento.marca" class="mt-1">
            <b-col cols="4">
              <b>Marca:</b>
            </b-col>
            <b-col>{{ implemento.marca }}</b-col>
          </b-row>
          <b-row v-if="implemento.modelo" class="mt-1">
            <b-col cols="4">
              <b>Modelo:</b>
            </b-col>
            <b-col>{{ implemento.modelo }}</b-col>
          </b-row>
          <b-row v-if="implemento.observacao" class="mt-1">
            <b-col cols="4">
              <b>Obsercação:</b>
            </b-col>
            <b-col>{{ implemento.observacao }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b>Valor Coberto:</b>
            </b-col>
            <b-col>{{ implemento.valorCobertura | currency }}</b-col>
          </b-row>

          <hr v-if="itemDetalheAux.implemento" />
        </div>
      </div>

      <div
        v-if="
          this.itemDetalheAux.agregado &&
          this.itemDetalheAux.agregados.length > 0
        "
      >
        <div v-for="(agregado, index) in itemDetalheAux.agregados" :key="index">
          <b-row class="mt-3">
            <b-col cols="1">
              <span
                :class="`badge badge-${getClasStatus(
                  itemDetalheAux.nomeStatus,
                )}`"
              >
                <font-awesome-icon
                  :icon="['fa', `${getIconeTipo(agregado.tipo)}`]"
                  class="font14"
                />
              </span>
            </b-col>
            <b-col>
              {{
                formataNome(agregado.marca) + ' ' + formataNome(agregado.modelo)
              }}
              <div class="text-secondary">
                {{ agregado.placa }}
              </div>
            </b-col>
            <b-col cols="3" class="text-center padding-0">
              {{ somaValorTotal(agregado.produtosAgregado) | currency }}
              <div class="text-secondary">
                {{ somaValorTotalMes(agregado.produtosAgregado) | currency }} /
                mês
              </div>
            </b-col>
            <b-col cols="3" class="text-center padding-0">
              {{ itemDetalheAux.nomeStatus }}
              <div class="text-secondary">{{ exibeDias(itemDetalheAux) }}</div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="4">
              <b>Tipo Veiculo:</b>
            </b-col>
            <b-col>{{ agregado.tipo }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b>Valor Coberto:</b>
            </b-col>
            <b-col>{{ agregado.valorCobertura | currency }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b>Participação:</b>
            </b-col>
            <b-col>
              <span
                v-if="
                  agregado.porcentagemParticipacao &&
                  agregado.valorMinimoParticipacao > 0
                "
                >{{ agregado.valorMinimoParticipacao | currency }} +</span
              >
              {{ agregado.porcentagemParticipacao }}% da FIPE, mínimo de
              {{ agregado.valorMinimoParticipacao | currency }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b>Mensalidade:</b>
            </b-col>
            <b-col>
              {{ somaValorTotalMes(agregado.produtosAgregado) | currency }}
              <span v-if="agregado.diaVencimentoMensalidade"
                >todo dia {{ agregado.diaVencimentoMensalidade }}</span
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b>Adesão:</b>
            </b-col>
            <b-col>{{
              somaValorTotal(agregado.produtosAgregado) | currency
            }}</b-col>
          </b-row>
          <hr />

          <b-row class="mt-3">
            <b-col cols="3">
              <b>Produtos:</b>
            </b-col>
            <b-col>
              <ul class>
                <li
                  v-for="prodAgregado in agregado.produtosAgregado"
                  :key="prodAgregado.idProduto"
                >
                  {{ prodAgregado.nomeProduto }}
                </li>
              </ul>
            </b-col>
          </b-row>
          <hr v-if="itemDetalheAux.agregado" />
        </div>
      </div>
      <b-row v-if="decoded.tipo == 'Consultor'">
        <b-col class="text-center text-secondary">
          <a :href="`tel:${itemDetalheAux.dadosCliente.telefone}`">
            <font-awesome-icon icon="phone" />
            <br />
            <b>Ligar</b>
          </a>
        </b-col>
        <b-col class="text-center text-secondary">
          <a :href="`mailto:${itemDetalheAux.dadosCliente.email}`">
            <font-awesome-icon icon="envelope" />
            <br />
            <b>Email</b>
          </a>
        </b-col>
        <b-col class="text-center text-secondary">
          <a
            target="_blank"
            :href="`https://api.whatsapp.com/send?phone=55${
              itemDetalheAux.dadosCliente.celular
                ? itemDetalheAux.dadosCliente.celular
                : itemDetalheAux.dadosCliente.telefone
                    .replace('(', '')
                    .replace(')', '')
                    .replace(' ', '')
                    .replace('-', '')
            }`"
          >
            <i class="fa fa-whatsapp"></i>
            <br />
            <b>Whatsapp</b>
          </a>
        </b-col>
        <b-col class="text-center text-secondary">
          <a
            target="_blank"
            :href="`${getWindow().replace('app', 'adm')}/proposta/${
              itemDetalheAux.chave
            }`"
          >
            <font-awesome-icon icon="file-alt" />
            <br />
            <b>Proposta</b>
          </a>
        </b-col>
        <!--b-col class="text-center text-secondary">
          <font-awesome-icon icon="calendar-alt" />
          <br />
          <b>Agendar</b>
        </b-col-->
      </b-row>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import moment from 'moment';
import service from './../services';
export default {
  name: 'DashBoard',
  props: ['itemDetalhe', 'selecionouCotacao'],
  data() {
    return { decoded: {}, itemDetalheAux: null, carregando: false };
  },
  watch: {
    selecionouCotacao: function (newValue, oldValue) {
      console.log('aaaaaaaaaaaaallll');
      if (newValue == true) this.buscaDados();
    },
  },
  methods: {
    getWindow() {
      return window.location.origin;
    },
    formataDataUTC(data) {
      return moment(data).utc().format('DD/MM/YYYY HH:mm');
    },
    somaValorTotalMes(produtos) {
      var total = 0;
      produtos.forEach((element) => {
        if (element.isMensal == true) total = total + element.valorFinal;
      });
      return total;
    },
    somaValorTotal(produtos) {
      var total = 0;
      produtos.forEach((element) => {
        if (element.isMensal != true) total = total + element.valorFinal;
      });
      return total;
    },
    exibeDias(item) {
      if (item.nomeStatus == 'Em Aberto') {
        if (item.diasEmAberto == 1) {
          return '1 dia';
        } else {
          return `${item.diasEmAberto} dias`;
        }
      }
    },
    getClasStatus(status) {
      return status.split(' ')[0].replace(' ', '');
    },
    getIconeTipo(tipo) {
      if (tipo == 'Carro') return 'car';
      if (tipo == 'Moto') return 'motorcycle';
      if (tipo == 'Caminhão') return 'truck';
      if (tipo == 'implemento') return 'truck';
      if (tipo == 'agregado') return 'truck';
    },
    formataNome(nome) {
      var aux = nome.split(' ');
      var novoNome = '';
      if (aux[1]) novoNome = aux[0] + ' ' + aux[1];
      else novoNome = aux[0];
      return novoNome;
    },
    returnLista() {
      this.$emit('FechaDetalhe');
    },
    buscaDados() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        //this.mockSwipeList[0].push(this.template2);
        var form = { ...this.decoded };
        form.idProposta = this.itemDetalhe.id_proposta;

        if (form.idProposta)
          service.post('Cotacao', 'listarCotacoes', form).then((res) => {
            this.itemDetalheAux = res.data[0];
            this.itemDetalheAux.valorMinimoParticipacao =
              this.itemDetalheAux.valorParticipacaoMinimo;
            this.itemDetalheAux.valorPorcentagemParticipacao =
              this.itemDetalheAux.valorCotaParticipacao;
            this.carregando = false;
            resolve();
          });
      });
    },
    openDecoded() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
      }
    },
    ajustaDataHoraUtc(data) {
      return moment.utc(data).local().format('DD/MM/YYYY HH:mm');
    },
  },
  mounted() {
    this.openDecoded();
    this.buscaDados();
    console.log('monted', this.itemDetalhe);
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.detalhe {
  font-size: 12px;
  padding-bottom: 110px !important;
}
</style>
