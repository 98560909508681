var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"listaCotacoes"},[(_vm.cotacoes.length == 0)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(" Nenhuma Cotação Encontrada ")]):_c('swipe-list',{ref:"list",staticClass:"card",attrs:{"disabled":!_vm.enabled,"items":_vm.cotacoes,"item-key":"id","revealed":_vm.revealed,"item-disabled":function (ref) {
        var disabled = ref.disabled;

        return disabled;
}},on:{"update:revealed":function($event){_vm.revealed=$event},"closed":function($event){return _vm.setLastEvent('closed', $event)},"leftRevealed":function($event){return _vm.setLastEvent('leftRevealed', $event)},"rightRevealed":function($event){return _vm.setLastEvent('rightRevealed', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var revealLeft = ref.revealLeft;
var revealRight = ref.revealRight;
var close = ref.close;
var revealed = ref.revealed;
return [_c('div',{ref:"content",staticClass:"card-content",on:{"click":function($event){return _vm.itemClick(item)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"1"}},[_c('span',{class:("badge badge-" + (_vm.getClasStatus(item.nomeStatus)))},[_c('font-awesome-icon',{staticClass:"font14",attrs:{"icon":['fa', ("" + (_vm.getIconeTipo(item.codTipoVeiculo)))]}})],1)]),_c('b-col',[_vm._v(" "+_vm._s(_vm.formataNome(item.nome))+" "),_c('div',{staticClass:"text-secondary"},[_vm._v(_vm._s(item.placa))])]),_c('b-col',{staticClass:"text-center padding-0",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.nomeStatus)+" "),_c('div',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.exibeDias(item)))])]),(
              _vm.decoded.tipo != 'Associado' || item.nomeStatus == 'Finalizada'
            )?_c('b-col',{attrs:{"cols":"1"}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left"}})],1):_vm._e()],1),(item.exibeDetalhe == true)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"1"}}),_c('b-col',{staticClass:"pb-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Protocolo:")])]),_c('b-col',[_vm._v(_vm._s(item.id_proposta))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Consultor:")])]),_c('b-col',[_vm._v(_vm._s(item.nomeVendedor))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Tipo:")])]),_c('b-col',[_vm._v(_vm._s(item.tipoProposta))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Email:")])]),_c('b-col',[_vm._v(_vm._s(item.email))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Telefone:")])]),_c('b-col',[_vm._v(_vm._s(item.telefone ? item.telefone : item.celular))])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Cidade:")])]),_c('b-col',[_vm._v(_vm._s(item.cidade))])],1),(item.dataVistoria)?_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Vistoria:")])]),_c('b-col',[_vm._v(_vm._s(_vm.formataData(item.dataVistoria)))])],1):_vm._e(),(item.motivoReprovacaoGeral)?_c('b-row',[_c('b-col',{staticClass:"text-center mt-2"},[_c('p',{staticClass:"alert alert-danger"},[_vm._v(" "+_vm._s(item.motivoReprovacaoGeral)+" ")])])],1):_vm._e()],1)],1):_vm._e(),(
            _vm.decoded.tipo == 'Associado' &&
            item.nomeStatus != 'Aguardando Aprovação' &&
            item.nomeStatus != 'Finalizada'
          )?_c('b-row',{staticClass:"mt-2"},[(_vm.utils.verificaEmpresaBemProtege(item.id_empresa))?_c('b-col',{staticClass:"bg-light text-center box",attrs:{"cols":"6"}},[_c('a',{staticClass:"text-dark",attrs:{"target":"_blank","href":_vm.redirectVistoriaV2(item.chave)}},[_c('div',{staticClass:"iconeChart bg-success"},[_c('img',{attrs:{"src":" https://bboneapp.s3.amazonaws.com/mobile/car.png"}})]),(item.nomeStatus == 'Em Aberto')?_c('span',[_vm._v("Realizar Vistoria")]):_c('span',[_vm._v("Corrigir Vistoria")])])]):_c('b-col',{staticClass:"bg-light text-center box",attrs:{"cols":"6"},on:{"click":function($event){return _vm.aceitarProposta(item, true)}}},[_c('div',{staticClass:"iconeChart bg-success"},[_c('img',{attrs:{"src":" https://bboneapp.s3.amazonaws.com/mobile/car.png"}})]),(item.nomeStatus == 'Em Aberto')?_c('span',[_vm._v("Realizar Vistoria")]):_c('span',[_vm._v("Corrigir Vistoria")])]),_c('b-col',{staticClass:"bg-light text-center box",attrs:{"cols":"6"},on:{"click":function($event){return _vm.verDetalhes(item)}}},[_c('div',{staticClass:"iconeChart"},[_c('i',{staticClass:"fa fa-search-plus text-white"})]),_c('span',[_vm._v("Detalhes")])])],1):_vm._e()],1)]}},{key:"right",fn:function(ref){
          var item = ref.item;
          var close = ref.close;
          var index = ref.index;
return [(
          item.nomeStatus == 'Em Aberto' &&
          _vm.decoded.tipo == 'Consultor' &&
          _vm.decoded.id_empresa != 117
        )?_c('div',{staticClass:"swipeout-action orange",on:{"click":function($event){return _vm.atualizarProdutosCotacao(item)}}},[_c('i',{staticClass:"fa fa-edit"})]):_vm._e(),(
          item.nomeStatus != 'Aguardando Aprovação' &&
          item.nomeStatus != 'Finalizada' &&
          item.nomeStatus != 'Em Pré-Análise' &&
          _vm.decoded.tipo == 'Consultor' &&
          _vm.decoded.podeFazerVistoria == true &&
          _vm.utils.verificaEmpresaBemProtege(item.id_empresa)
        )?_c('div',{staticClass:"swipeout-action black"},[_c('a',{staticClass:"text-white",attrs:{"target":"_blank","href":_vm.redirectVistoriaV2(item.chave)}},[_c('i',{staticClass:"fa fa-camera"})])]):_vm._e(),(
          item.nomeStatus != 'Aguardando Aprovação' &&
          item.nomeStatus != 'Finalizada' &&
          item.nomeStatus != 'Em Pré-Análise' &&
          _vm.decoded.tipo == 'Consultor' &&
          _vm.decoded.podeFazerVistoria == true &&
          !_vm.utils.verificaEmpresaBemProtege(item.id_empresa)
        )?_c('div',{staticClass:"swipeout-action black",on:{"click":function($event){return _vm.aceitarProposta(item, true)}}},[_c('i',{staticClass:"fa fa-camera"})]):_vm._e(),(item.nomeStatus != 'Finalizada')?_c('div',{staticClass:"swipeout-action blue",on:{"click":function($event){return _vm.verDetalhes(item)}}},[_c('i',{staticClass:"fa fa-search-plus"})]):_c('div',{staticClass:"swipeout-action blue"},[_c('a',{staticClass:"text-white",attrs:{"target":"_blank","href":((_vm.getWindow()) + "/proposta/" + (item.chave))}},[_c('i',{staticClass:"fa fa-search-plus"})])]),(item.nomeStatus == 'Em Aberto' && _vm.decoded.tipo == 'Consultor')?_c('div',{staticClass:"swipeout-action purple",on:{"click":function($event){return _vm.copiarText(item.chave)}}},[_c('i',{staticClass:"fa fa-copy"})]):_vm._e(),(_vm.utils.verificaEmpresaBemProtege(item.id_empresa))?_c('div',{staticClass:"swipeout-action orange"},[_c('a',{staticClass:"text-white",attrs:{"target":"_blank","href":((_vm.getWindow().replace('app', 'adm')) + "/contratoAdesao/" + (item.chave))}},[_c('i',{staticClass:"fa fa-copy"})])]):_vm._e(),(item.nomeStatus == 'Em Aberto' && _vm.decoded.tipo == 'Consultor')?_c('div',{staticClass:"swipeout-action green",attrs:{"title":"Whatsapp"}},[_c('a',{staticClass:"text-white",attrs:{"target":"_blank","href":_vm.returnLinkWhats(item)}},[_c('i',{staticClass:"fa fa-whatsapp"})])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }