<template>
  <div class="text-center grafico">
    <apexchart
      height="200"
      type="donut"
      :options="options"
      :series="series"
      @legendClick="click"
    ></apexchart>
    <div v-if="exibeLoad" class="total text-center">
      Total:
      <b>{{ total }}</b>
    </div>
    <b-button v-if="isFiltro == true" @click="limpaFiltro" class="limparFiltro">
      <font-awesome-icon icon="times" class="mr-3" />Limpar Filtro
    </b-button>
  </div>
</template>
<!-- eslint-disable -->
<script>
export default {
  name: "DashBoard",
  props: ["options", "series", "total", "isLoad", "indexGrafico"],
  watch: {
    isLoad: function (newValue, oldValue) {
      console.log("whatch", newValue);
      this.exibeLoad = newValue;
    },
  },
  data() {
    return {
      isFiltro: false,
      exibeLoad: true,
      value: 80,
      max: 100,
    };
  },
  methods: {
    limpaFiltro() {
      this.$emit("FiltraLegenda", {
        limpaFiltro: true,
        indexGrafico: this.indexGrafico,
      });
      this.isFiltro = false;
    },
    click(chartContext, seriesIndex, config) {
      this.isFiltro = true;
      this.$emit("FiltraLegenda", {
        IndexLegenda: seriesIndex,
        indexGrafico: this.indexGrafico,
      });
    },
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grafico {
  font-size: 10px;
}
.total {
  position: relative;
  top: -120px;
  /*left: 130px;
  width: 95px;*/
  font-size: 14px;
}
.total b {
  display: block;
}
.limparFiltro {
  margin-top: -60px;
  float: right;
  position: relative;
  font-size: 12px;
}
</style>
